import React, { useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import FormFields from "../../../static/forms/brochure.json"
import brochure from "../../images/brochure-img.png"
import Map from "../PropertyMap/Map"
import DefaultForm from "../forms/default-form-layout"
import "./CampaignColBlock.scss"
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer"
import PlayVideo from "../PlayVideo/PlayVideo"
import getVideoId from "get-video-id"
import noImage from "../../images/no-image.png"
import GGFXImage from "../../modules/GGFXImage"



const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const CampaignColBlock = props => {
  var imagename = "campign.single_image_block.image"

  const [show, setShow] = useState(false)

  const [isPlay, setPlay] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  const handleVideo = item => {
    setPlay(true)
    setVideoUrl(item?.video_url)
  }

  // const [isPlay, setPlay] = useState(false)


  let processedImages = JSON.stringify({})
  if (props?.imagetransforms) {
    processedImages = props?.imagetransforms?.single_image_block_Transforms
  }

  const loc = props.locationblock ? props?.left_right_block[0] : ""
  const pageurl = typeof window !== "undefined" ? window.location.href : ""


  const locationInfo = {
    latitude: parseFloat(loc.latitude),
    longitude: parseFloat(loc.longitude),
  }

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const brochureUrl = props.campaignData?.brochure_link?.url

  const formTitle = "Download the Brochure"

  return props?.left_right_block?.map((item, i) => {
    // console.log(props?.imagetransforms, "iiii", props?.tt)

    const videoid = item?.video_url && getVideoId(item?.video_url)
    let youTubeImage=""
      if(videoid?.id){
        youTubeImage= `https://i.ytimg.com/vi/${videoid?.id}/maxresdefault.jpg`
      }
    return (
      <section
        id={props.locationblock ? "location-block" : ""}
        className={`tile-block-wrapper-campaign section_item ${
          props.singleblock ? "singleblock" : ""
        } position-relative  ${
          item?.layout === "right" ? "tile-right" : "tile-left"
        }`}
      >
        <Container>
          <Row
            className={`d-flex align-items-center ${
              item?.layout === "right" ? "flex-row-reverse" : ""
            }`}
          >
            <Col xl={6}>
              {props.locationblock && locationInfo.latitude ? (
                <div className="campaign-map-location">
                  <Map property_details_map_data={locationInfo} />
                </div>
              )
              :
              <div className="tile-img-wrapper img-zoom">
              {
              item?.image?.url?
                <GGFXImage
                  ImageSrc={item?.image}
                  altText={item?.image?.alternativeText}
                  imagetransforms={props?.campaignData?.ggfx_results}
                  renderer="srcSet"
                  imagename={imagename}
                  strapiID={props?.id}
                />
              :
              item?.video_url?
                <img className="thumbnail" src={youTubeImage} alt=""/>
              :
                <img classNames="img-fluid" src={noImage} alt="No Image" />
              }
              {item?.video_url && (
                <button
                  onClick={() => handleVideo(item)}
                  className="play-button"
                >
                  <i className="icon video-filled-icon" />
                </button>
              )}
              </div>
              
              }
              
            </Col>
            <Col xl={1}></Col>
            <Col xl={5}>
              {item?.title && <h3>{item?.title}</h3>}
              {item?.description && (
                <ContentModule Content={item.description?.data?.description || item?.description} />
              )}
              {item?.cta_label === "download brochure" ? (
                <a
                  onClick={handleModal}
                  class="button button-outline-grey"
                  href="javascript:void(0)"
                >
                  {item?.cta_label}
                </a>
              ) : item?.cta_label ? (
                <PreviewCTALink
                  class="button button-outline-grey "
                  link={
                    item?.menu
                      ? item.menu
                      : { external_link: item?.custom_link }
                  }
                  title={item?.cta_label}
                />
              ) : null}
            </Col>
          </Row>
        </Container>

        {/* <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={item?.video_url}
          htmlink={""}
        /> */}

<PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={videoUrl}
        htmlink={""}
      />

        <Modal
          show={show}
          // onHide={handleClose}
          backdrop="static"
          centered
          dialogClassName="modal-popup-form brochure"
        >
          <Modal.Body>
            <div className="popup-form-wrapper brochure">
              <div className="close-modal" onClick={() => handleClose()}>
                <i className="icon brochure-cancel"></i>
              </div>
              <div className="image-section">
                <img src={brochure} alt="brochure" />
              </div>

              <div>
                <DefaultForm
                  fields={FormFields}
                  formTitle={formTitle}
                  emailSubUser={
                    props.campaignData?.title + " " + "Download Brochure"
                  }
                  emailSubAdmin={
                    props.campaignData?.title + " " + "Download Brochure"
                  }
                  pageTitle={props?.campaignData?.title}
                  brochureUrl={brochureUrl}
                  form_id_value="campign-brochure-id"
                  phone_id_value="desktop-id-campaign-brochure"
                  sourceUrl={pageurl}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        
      </section>
    )
  })
}

export default CampaignColBlock
