import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import NoImage from "../../../static/images/no-image.png"
import "./CampaignPropertyCard.scss"
import GGFXImage from "../../modules/GGFXImage"
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const CampaignPropertyCard = ({
  images,
  developer,
  development_type,
  crm_id,
  imagetransforms,
  strapi_id,
  project_title,
  slug,
  area,
  ggfx_results
}) => {
  let processedImages
  processedImages = JSON.stringify({})

  const imagename = "new-developments.images.tileimg"

  return (
    <ScrollAnimation
      className="property-card-section"
      animateIn="animate__slideInUp"
      animateOnce
      delay={100}
      offset={100}
    >
      {/* <div className="property-card-section"> */}
      <div className="image-section img-zoom">
        <Link
          to={`/new-developments-details/${slug}/${crm_id?.toLowerCase()}/`}
        >
          {images ? (
            // <ImageModule
            //   ImageSrc={images}
            //   altText={`${project_title}`}
            //   imagetransforms={processedImages}
            //   renderer="pic-src"
            //   imagename={imagename}
            //   strapi_id={strapi_id}
            //   classNames="property-card_image"
            // />
            <GGFXImage
              ImageSrc={images}
              altText={`${project_title}`}
              imagetransforms={ggfx_results}
              renderer="srcSet"
              imagename={imagename}
              strapiID={strapi_id}
              classNames="property-card_image"

            />
          ) : (
            // <img src={image.url}/>
            <img src={NoImage} alt="img" />
          )}
        </Link>
      </div>
      <div className="content">
        <Link
          to={`/new-developments-details/${slug}/${crm_id?.toLowerCase()}/`}
        >
          <h3 className="address">{project_title}</h3>
        </Link>
        <div className="more-details-offplan">
          <p>
            <span className="make-bold">Location:</span>
            <span>{area}</span>{" "}
          </p>
          <p>
            <span className="make-bold">Developer:</span>
            <span>{developer}</span>{" "}
          </p>
          <p>
            <span className="make-bold">Development Type:</span>
            <span>{development_type}</span>{" "}
          </p>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default CampaignPropertyCard
